@font-face {
	font-family: 'Fira Code';
	src: url('woff2/FiraCode-Light.woff2') format('woff2'),
	url("woff/FiraCode-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Code';
	src: url('woff2/FiraCode-Regular.woff2') format('woff2'),
	url("woff/FiraCode-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Code';
	src: url('woff2/FiraCode-Medium.woff2') format('woff2'),
	url("woff/FiraCode-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Code';
	src: url('woff2/FiraCode-SemiBold.woff2') format('woff2'),
	url("woff/FiraCode-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Code';
	src: url('woff2/FiraCode-Bold.woff2') format('woff2'),
	url("woff/FiraCode-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Code VF';
	src: url('woff2/FiraCode-VF.woff2') format('woff2-variations'),
	url('woff/FiraCode-VF.woff') format('woff-variations');
	/* font-weight requires a range: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Fonts/Variable_Fonts_Guide#Using_a_variable_font_font-face_changes */
	font-weight: 300 700;
	font-style: normal;
}